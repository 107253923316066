.container-classes {
    @apply mx-auto md:px-5 lg:px-6 max-w-[73.25rem] mb-5 pb-8;
    ~ .container-classes {
        @apply bg-[#F6F6F6] max-w-none md:pb-16 overflow-hidden;
        :global {
            .recent-related-container {
                @apply max-w-7.5xl my-0 mx-auto;
            }   
        }
    }

    :global {
        .slick-list,
        .slick-track,
        .slick-initialized .slick-slide {
            @apply flex;
        }
        .slick-list {
            @apply overflow-visible md:overflow-hidden;
        }
        .slick-slide {
            @apply h-auto;
            > div,
            .recently-viewed-product-item,
            .related-products-product-item {
                @apply h-full w-full;
                > div {
                    @apply h-full;
                }
            }
            .recently-viewed-product-item {
                @apply px-0 md:px-2.5;
            }
        }
        .productitem-block {
            @apply h-full;
        }
        .productitem-details,
        .productitem-name {
            @apply flex-1;
        }
        .productitem-details {
            @apply flex flex-col flex-wrap;
        }
        .product-price {
            @apply items-start;
        }
        .slick-slider {
            @apply -mx-[0.3125rem] md:mx-0;
            .slick-prev,
            .slick-next {
                @apply top-1/2;
            }
            .slick-prev {
                @apply -left-[.875rem] md:-left-[.8rem];
            }
            .slick-next {
                @apply -right-[.75rem] md:-right-2.5;
            }
            .slick-dots {
                @apply flex #{!important};
                @apply px-[0.3125rem] md:px-0 static md:max-w-[73.5rem] md:mx-auto mt-4 md:mt-8 overflow-hidden items-center;
                li {
                    @apply h-1 w-auto flex-grow m-0;
                    button {
                        @apply w-full h-full rounded-none bg-blue-3;
                    }
                    &.slick-active {
                        button {
                            @apply bg-grey-1;
                        }
                    }
                }
            }
        }
        [class*='Recentviewed_recently-viewed-products_'] {
            @apply pt-0;

            [class*='Recentviewed_heading-container'] {
                @apply pt-8 pb-6 pl-0 md:pl-2.5;
                [class*='Recentviewed_title_'] {
                    @apply text-xl md:text-[2rem] leading-7 md:leading-[3rem] text-blue-1;
                }
            }
            [class*='Recentviewed_products-list_'] {
                [class*='Recentviewed_product-item_'] {
                    @apply py-0 px-[0.3125rem] md:px-2.5;
                }
                .slick-disabled {
                    @apply block #{!important};
                }
            }
        }
        hr {
            @apply border-solid border-grey-3;
        }
        html & .toast-container {
            @apply -mt-2.5 mb-[1.375rem] pl-4 pt-[0.6875rem] md:pt-[0.5625rem] md:pl-[1.6875rem] md:mt-[1.875rem] md:-mb-[1.125rem] md:pr-[1.625rem] 2xl:ml-1.5 2xl:-mr-[2.125rem] 2xl:w-auto;
            .toast-content-inner {
                @apply gap-x-2 md:gap-x-6;
                > .toast-text {
                    @apply mx-0;
                    h3 {
                        @apply hidden md:block;
                    }
                    a {
                        @apply lowercase relative z-1;
                    }
                }
                + svg {
                    @apply w-[0.6875rem] h-[0.6875rem];
                }
            }
        }
        .recent-related-container {
            @apply px-6 md:px-0;
        }
    }
}
.container-classes-yotpo {
    :global {
        .yotpo {
            &.yotpo-main-widget,
            .yotpo-modal-dialog {
                @apply max-w-[72.8125rem];
            }
            &.yotpo.yotpo-main-widget {
                @apply pt-[5.625rem] lg:pt-[6.5625rem];
                .yotpo-icon-btn {
                    .yotpo-icon {
                        @apply md:inline-block align-middle text-gold-1 #{!important};
                        &.write-question-review-button-icon {
                            @apply hidden #{!important};
                        }
                    }
                    .yotpo-icon-button-text {
                        @apply md:inline-block align-middle text-gold-1 #{!important}
                    }
                    .yotpo-icon-linkedin {
                        @apply md:-mt-1.5;
                    }
                }
    
                .yotpo-messages {
                    &:has(.yotpo-thank-you:not(.yotpo-hidden)) {
                        @apply mt-[5.625rem] lg:mt-[6.5625rem];
                    }
                    .yotpo-thank-you {
                        @apply mt-6;
                    }
                }
            }
            .bottom-line-items-container-desktop,
            .bottom-line-items-container-mobile {
                .bottom-line-items-wrapper {
                    .avg-score {
                        @apply font-secondary text-2xl text-t-4 #{!important};
                    }
                    .reviews-qa-labels-container .reviews-qa-label {
                        @apply font-normal text-t-4 #{!important};
                    }
                }
            }
            .new-yotpo-small-box.reviews {
                @apply hidden;
            }

            .yotpo-label-container,
            .yotpo-regular-box,
            .new-yotpo-small-box {
                @apply border-none #{!important};
            }
            .yotpo-display-wrapper {
                @apply px-5;
            }
            .write-review-wrapper {
                &.visible {
                    @apply border-b border-solid border-[var(--border-color)] border-l-0 border-r-0 border-t-0;
                }
            }
            .yotpo-reviews {
                @apply pt-2 md:pt-9;
                .yotpo-review {
                    &.yotpo-regular-box {
                        @apply md:flex md:flex-row md:flex-wrap;
                        @apply pt-[2.125rem] pb-5 border-b border-solid border-[var(--border-color)] border-l-0 border-r-0 border-t-0 #{!important};
                        &.yotpo-regular-box-filters-padding {
                            @apply border-t border-solid border-[var(--border-color)] border-l-0 border-r-0 #{!important};
                        }
                    }
                }
            }
            .yotpo-no-reviews {
                @apply pb-7 border-b border-solid border-[var(--border-color)] border-l-0 border-r-0 border-t-0;
            }
            .yotpo-regular-box {
                @apply pb-[1.5625rem] pt-[0.8125rem] md:py-[0.8125rem] #{!important};
                .yotpo-header {
                    .yotpo-icon-profile {
                        @apply hidden;
                    }
                    .yotpo-user-name {
                        @apply font-bold leading-6 md:leading-7;
                        @apply text-sm md:text-lg text-t-4 #{!important};
                    }
                    .label-with-tooltip {
                        @apply text-sm leading-6 -mt-[0.3125rem] md:mt-0;
                        .yotpo-user-title {
                            @apply align-bottom font-medium;
                            @apply text-t-4 #{!important};
                        }
                    }
                    .yotpo-icon {
                        @apply text-[1.0625rem];
                    }
                    .yotpo-review-stars {
                        @apply mt-[0.1875rem] md:mt-2.5;
                    }
                    .yotpo-header-element {
                        @apply -mt-2.5 md:mt-0;
                    }
                }
                .yotpo-header,
                .yotpo-footer {
                    .yotpo-review-date {
                        @apply leading-6 font-bold whitespace-nowrap;
                        @apply text-sm text-t-4 pt-2 #{!important};
                    }
                }
                .yotpo-main {
                    form {
                        @apply px-5 md:px-0;
                    }
                    .content-title {
                        @apply text-sm leading-6;
                        @apply text-t-4 pb-0.5 #{!important};
                    }
                    .content-review {
                        @apply text-sm leading-6;
                        @apply text-grey-1 #{!important};
                    }
                }
                .yotpo-footer {
                    @apply md:w-full;
                    .yotpo-icon {
                        @apply text-lg;
                        &.yotpo-icon-share {
                            @apply text-blue-4;
                            ~ .y-label {
                                @apply text-sm leading-6 font-medium;
                                @apply text-t-4 #{!important};
                            }
                        }

                        &.yotpo-icon-seperator {
                            @apply hidden;
                        }
                    }
                    .yotpo-helpful {
                        .y-label {
                            @apply text-sm leading-6 font-medium;
                            @apply text-t-4 #{!important};
                        }
                        .yotpo-icon-thumbs-up,
                        .yotpo-icon-thumbs-down {
                            @apply text-grey-1 #{!important};
                        }
                        .vote-sum {
                            @apply font-normal text-[0.8125rem];
                            @apply text-t-4 #{!important};
                        }
                    }
                }
                .yotpo-main {
                    @apply mt-[1.125rem] md:mt-2.5;
                }
                .yotpo-main,
                .yotpo-footer {
                    @apply ml-0;
                }
            }
            .yotpo-nav-content {
                .yotpo-header {
                    @apply md:flex md:flex-col md:flex-wrap md:w-[28.5%];
                }
                .yotpo-main {
                    @apply md:w-[71.5%] md:mt-[0.1875rem] md:pl-[0.3125rem];
                }
                .filters-container .clear-text-icon {
                    @apply top-[1.125rem] #{!important};
                }
            }
            .yotpo-default-button,
            input[type='button'].yotpo-default-button {
                @apply bg-white hover:bg-white  #{!important};
            }
            .yotpo-default-button:not([data-network]),
            input[type='button'].yotpo-default-button:not([data-network]) {
                @apply rounded-full border border-gold-1 border-solid hover:border-2 leading-[1.875rem] h-[2.1875rem] px-7 w-auto text-gold-1 text-xs hover:font-bold #{!important};
            }

            .write-review .yotpo-header .yotpo-header-title {
                @apply text-2xl md:text-[2rem] leading-[3rem] -tracking-wider text-t-4 #{!important};
            }
            .reviews-header {
                .reviews-amount {
                    @apply hidden #{!important};
                }
                .yotpo-drop-down-layout {
                    @apply float-right w-1/2;
                    .yotpo-dropdown {
                        @apply w-[7.3125rem];
                    }
                }
            }
            .filters-container {
                .filters-container-title {
                    @apply text-xs leading-[1.125rem] font-bold text-t-4 #{!important};
                }
                .free-text-search-input {
                    @apply touch:text-base text-xs text-grey-1 border-x-0  border-t-4 border-t-0 bg-transparent h-[1.875rem] mb-7 md:mb-9 #{!important};
                    &::placeholder {
                        @apply normal-case text-grey-9 text-xs;
                    }
                }
                .magnifying-glass-icon {
                    @apply top-3.5;
                }
                .dropdown-filters-container {
                    @apply w-1/2 md:w-auto;
                }

                .desktop-clear-all-btn {
                    @apply rounded-full border border-gold-1 border-solid hover:border-2  #{!important};
                    .desktop-clear-btn-text {
                        @apply leading-[1.875rem] h-[2.1875rem] px-7 w-auto text-gold-1 text-xs hover:font-bold inline-block p-0.5 #{!important};
                    }
                }
            }
            .yotpo-icon-clear-text-search {
                .clear-text-icon {
                    @apply top-5 #{!important};
                }
            }

            .yotpo-nav-dropdown {
                @apply float-none flex justify-end -mt-[2.55rem];
            }
            .filters-dropdown {
                @apply w-full md:w-auto;
                @apply mt-2.5 md:mt-[0.9375rem] #{!important};
            }
            .filters-dropdown,
            .sort-drop-down {
                .yotpo-dropdown-button {
                    @apply relative;
                    @apply w-full md:w-[11.0625rem] h-[2.375rem] border-0 md:border #{!important};

                    .selected {
                        @apply text-grey-1 #{!important};
                    }
                }
                .yotpo-dropdown-button,
                .yotpo-dropdown-button:hover {
                    @apply rounded-3xl #{!important};
                }
                .yotpo-dropdown {
                    @apply w-[11.0625rem] #{!important};
                }
            }

            .suggested-topics-row {
                @apply mb-2 md:mb-4 #{!important};
                .suggested-topic,
                .suggested-topic-expand {
                    @apply bg-[#D7F0FF] py-2.5 px-4 mb-0 mr-4 inline-block #{!important};
                    .suggested-topic-text {
                        @apply text-grey-1 text-sm font-normal #{!important};
                    }
                }
                .suggested-topic-expand {
                    @apply mt-[0.8125rem] md:mt-0 #{!important};
                }
            }
            .yotpo-mandatory-explain {
                @apply font-primary text-blue-4 uppercase text-xs font-bold mb-[1.125rem] tracking-wider;
                .yotpo-mandatory-mark {
                    @apply text-xs tracking-tighter;
                }
            }
            span.yotpo-mandatory-mark {
                @apply text-blue-4 #{!important};
            }
            .form-element {
                .yotpo-mandatory-mark {
                    @apply text-accents-7 #{!important};
                }
            }
            .form-group .form-element .y-label,
            .yotpo-footer .y-label {
                @apply text-xs font-bold;
                @apply text-accents-7 #{!important};
            }
            input,
            .y-input:not(textarea) {
                @apply text-grey-2 h-[3.125rem] #{!important};
            }
            .y-label,
            span.y-label {
                @apply pt-0;
            }
            input,
            .y-input {
                @apply touch:text-base text-xs mt-[0.4375rem] mb-[1.625rem] min-w-0 #{!important};
            }
            .text-box,
            .yotpo-text-box {
                @apply text-grey-2 min-h-[10rem] p-4 #{!important};
            }
            .yotpo-icon-down-triangle {
                &:before {
                    @apply w-5 h-5 absolute top-2.5 right-2.5 bg-no-repeat bg-center;
                    content: '';
                    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNiAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjAyOTk0IDguNzQ0TDAuNTk2ODA2IDIuNTE0NjdDMC4yNDU1MDkgMi4xNTIgMC4yNDU1MDkgMS41OTczMyAwLjU5NjgwNyAxLjI1NkMwLjk0ODEwNCAwLjkxNDY2NSAxLjU0MDkyIDAuOTE0NjY1IDEuODkyMjIgMS4yNTZMNy42NjY2NyA2Ljg2NjY3TDEzLjQ0MTEgMS4yNTZDMTMuODE0NCAwLjkxNDY2NyAxNC4zODUyIDAuOTE0NjY3IDE0LjczNjUgMS4yNTZDMTUuMDg3OCAxLjU5NzMzIDE1LjA4NzggMi4xNTIgMTQuNzM2NSAyLjUxNDY3TDguMzI1MzUgOC43NDRDNy45NTIwOSA5LjA4NTMzIDcuMzgxMjQgOS4wODUzMyA3LjAyOTk0IDguNzQ0WiIgZmlsbD0iYmxhY2siIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMC41Ii8+Cjwvc3ZnPgo=');
                }
            }
            .main-widget {
                .yotpo-nav-content {
                    @apply mt-12;
                }
                .yotpo-reviews-header {
                    @apply p-0 #{!important};
                }
                .yotpo-reviews-filters {
                    @apply pt-0 pb-0 #{!important};
                }
            }

            .write-review {
                .yotpo-header {
                    @apply pt-[1.0625rem] md:pt-[0.8125rem] mb-[.875rem] #{!important};
                    .y-label {
                        @apply uppercase text-xs font-bold pb-6;
                        @apply text-accents-7 min-w-0 #{!important};
                        &.yotpo-header-title {
                            @apply pb-3 md:pb-6 text-t-4 #{!important};
                        }
                    }
                    .y-label ~ div {
                        @apply inline-block;
                    }
                    .stars-wrapper {
                        @apply -mt-1.5;
                    }
                    .yotpo-icon {
                        @apply text-xl;
                    }
                }
                .submit-button {
                    @apply float-none;
                    .yotpo-submit {
                        @apply mt-[0.3125rem] mb-[0.9375rem] #{!important};
                    }
                }
                .connect-wrapper {
                    @apply pl-0;
                }
                .socialize-wrapper {
                    &.visible {
                        @apply relative flex flex-col flex-wrap #{!important};
                    }
                    .yotpo-icon-btn {
                        @apply mb-0;
                        .yotpo-icon {
                            @apply text-gold-1;
                        }
                    }
                    .yotpo-or {
                        @apply mb-6 mt-2.5 #{!important};
                    }
                }
            }

            .yotpo-pager {
                @apply my-2.5;
                .yotpo-page-element {
                    @apply py-7 px-6 text-xs mr-0;
                    @apply text-black #{!important};

                    &.yotpo-icon-right-arrow {
                        @apply ml-2.5 mr-0 px-0;
                        &:before {
                            content: '';
                            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNSIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDUgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMC4xNDY5NTUgOS4zNTM5QzAuMTAwMzkxIDkuMzA3NDUgMC4wNjM0NDg2IDkuMjUyMjggMC4wMzgyNDIyIDkuMTkxNTNDMC4wMTMwMzU3IDkuMTMwNzkgNi4wOTMzZS0wNSA5LjA2NTY3IDYuMDkzMzhlLTA1IDguOTk5OUM2LjA5MzQ2ZS0wNSA4LjkzNDEzIDAuMDEzMDM1NyA4Ljg2OTAxIDAuMDM4MjQyMiA4LjgwODI3QzAuMDYzNDQ4NiA4Ljc0NzUyIDAuMTAwMzkxIDguNjkyMzQgMC4xNDY5NTUgOC42NDU5TDMuNzkzOTYgNC45OTk5TDAuMTQ2OTU1IDEuMzUzOUMwLjA1MzA2ODIgMS4yNjAwMSAwLjAwMDMyMzE5OSAxLjEzMjY3IDAuMDAwMzIzMiAwLjk5OTg5OUMwLjAwMDMyMzIwMiAwLjg2NzEyMyAwLjA1MzA2ODIgMC43Mzk3ODYgMC4xNDY5NTUgMC42NDU4OTlDMC4yNDA4NDEgMC41NTIwMTMgMC4zNjgxNzkgMC40OTkyNjggMC41MDA5NTUgMC40OTkyNjhDMC42MzM3MzEgMC40OTkyNjggMC43NjEwNjggMC41NTIwMTMgMC44NTQ5NTUgMC42NDU4OTlMNC44NTQ5NSA0LjY0NTlDNC45MDE1MiA0LjY5MjM1IDQuOTM4NDYgNC43NDc1MiA0Ljk2MzY3IDQuODA4MjdDNC45ODg4NyA0Ljg2OTAxIDUuMDAxODUgNC45MzQxMyA1LjAwMTg1IDQuOTk5OUM1LjAwMTg1IDUuMDY1NjcgNC45ODg4NyA1LjEzMDc5IDQuOTYzNjcgNS4xOTE1M0M0LjkzODQ2IDUuMjUyMjggNC45MDE1MiA1LjMwNzQ1IDQuODU0OTUgNS4zNTM5TDAuODU0OTU1IDkuMzUzOUMwLjgwODUwOSA5LjQwMDQ2IDAuNzUzMzMzIDkuNDM3NCAwLjY5MjU4OCA5LjQ2MjYxQzAuNjMxODQzIDkuNDg3ODIgMC41NjY3MjIgOS41MDA3OSAwLjUwMDk1NSA5LjUwMDc5QzAuNDM1MTg3IDkuNTAwNzkgMC4zNzAwNjYgOS40ODc4MiAwLjMwOTMyMSA5LjQ2MjYxQzAuMjQ4NTc2IDkuNDM3NCAwLjE5MzQgOS40MDA0NiAwLjE0Njk1NSA5LjM1MzlaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K');
                            @apply inline-block bg-no-repeat bg-center w-3 h-3 mt-0.5;
                        }
                        &.yotpo-disabled {
                            &:before {
                                content: '';
                                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNSIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDUgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNC44NTMxMSA5LjM1MzlDNC44OTk2NyA5LjMwNzQ1IDQuOTM2NjEgOS4yNTIyOCA0Ljk2MTgyIDkuMTkxNTNDNC45ODcwMyA5LjEzMDc5IDUgOS4wNjU2NyA1IDguOTk5OUM1IDguOTM0MTMgNC45ODcwMyA4Ljg2OTAxIDQuOTYxODIgOC44MDgyN0M0LjkzNjYxIDguNzQ3NTIgNC44OTk2NyA4LjY5MjM0IDQuODUzMTEgOC42NDU5TDEuMjA2MTEgNC45OTk5TDQuODUzMTEgMS4zNTM5QzQuOTQ2OTkgMS4yNjAwMSA0Ljk5OTc0IDEuMTMyNjcgNC45OTk3NCAwLjk5OTg5OUM0Ljk5OTc0IDAuODY3MTIzIDQuOTQ2OTkgMC43Mzk3ODYgNC44NTMxMSAwLjY0NTg5OUM0Ljc1OTIyIDAuNTUyMDEzIDQuNjMxODggMC40OTkyNjggNC40OTkxMSAwLjQ5OTI2OEM0LjM2NjMzIDAuNDk5MjY4IDQuMjM4OTkgMC41NTIwMTMgNC4xNDUxMSAwLjY0NTg5OUwwLjE0NTEwNiA0LjY0NTlDMC4wOTg1NDMyIDQuNjkyMzUgMC4wNjE2MDAzIDQuNzQ3NTIgMC4wMzYzOTM3IDQuODA4MjdDMC4wMTExODcxIDQuODY5MDEgLTAuMDAxNzg3MTMgNC45MzQxMyAtMC4wMDE3ODcxMyA0Ljk5OTlDLTAuMDAxNzg3MTMgNS4wNjU2NyAwLjAxMTE4NzEgNS4xMzA3OSAwLjAzNjM5MzcgNS4xOTE1M0MwLjA2MTYwMDMgNS4yNTIyOCAwLjA5ODU0MzIgNS4zMDc0NSAwLjE0NTEwNiA1LjM1MzlMNC4xNDUxMSA5LjM1MzlDNC4xOTE1NSA5LjQwMDQ2IDQuMjQ2NzMgOS40Mzc0IDQuMzA3NDcgOS40NjI2MUM0LjM2ODIyIDkuNDg3ODIgNC40MzMzNCA5LjUwMDc5IDQuNDk5MTEgOS41MDA3OUM0LjU2NDg3IDkuNTAwNzkgNC42Mjk5OSA5LjQ4NzgyIDQuNjkwNzQgOS40NjI2MUM0Ljc1MTQ5IDkuNDM3NCA0LjgwNjY2IDkuNDAwNDYgNC44NTMxMSA5LjM1MzlaIiBmaWxsPSIjQUJBOUE5Ii8+Cjwvc3ZnPgo=');
                                @apply inline-block bg-no-repeat bg-center w-3 h-3 -rotate-180 mt-0.5;
                            }
                        }
                    }
                    &.yotpo-icon-left-arrow {
                        @apply mr-2.5 px-0;
                        &:before {
                            content: '';
                            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNSIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDUgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMC4xNDY5NTUgOS4zNTM5QzAuMTAwMzkxIDkuMzA3NDUgMC4wNjM0NDg2IDkuMjUyMjggMC4wMzgyNDIyIDkuMTkxNTNDMC4wMTMwMzU3IDkuMTMwNzkgNi4wOTMzZS0wNSA5LjA2NTY3IDYuMDkzMzhlLTA1IDguOTk5OUM2LjA5MzQ2ZS0wNSA4LjkzNDEzIDAuMDEzMDM1NyA4Ljg2OTAxIDAuMDM4MjQyMiA4LjgwODI3QzAuMDYzNDQ4NiA4Ljc0NzUyIDAuMTAwMzkxIDguNjkyMzQgMC4xNDY5NTUgOC42NDU5TDMuNzkzOTYgNC45OTk5TDAuMTQ2OTU1IDEuMzUzOUMwLjA1MzA2ODIgMS4yNjAwMSAwLjAwMDMyMzE5OSAxLjEzMjY3IDAuMDAwMzIzMiAwLjk5OTg5OUMwLjAwMDMyMzIwMiAwLjg2NzEyMyAwLjA1MzA2ODIgMC43Mzk3ODYgMC4xNDY5NTUgMC42NDU4OTlDMC4yNDA4NDEgMC41NTIwMTMgMC4zNjgxNzkgMC40OTkyNjggMC41MDA5NTUgMC40OTkyNjhDMC42MzM3MzEgMC40OTkyNjggMC43NjEwNjggMC41NTIwMTMgMC44NTQ5NTUgMC42NDU4OTlMNC44NTQ5NSA0LjY0NTlDNC45MDE1MiA0LjY5MjM1IDQuOTM4NDYgNC43NDc1MiA0Ljk2MzY3IDQuODA4MjdDNC45ODg4NyA0Ljg2OTAxIDUuMDAxODUgNC45MzQxMyA1LjAwMTg1IDQuOTk5OUM1LjAwMTg1IDUuMDY1NjcgNC45ODg4NyA1LjEzMDc5IDQuOTYzNjcgNS4xOTE1M0M0LjkzODQ2IDUuMjUyMjggNC45MDE1MiA1LjMwNzQ1IDQuODU0OTUgNS4zNTM5TDAuODU0OTU1IDkuMzUzOUMwLjgwODUwOSA5LjQwMDQ2IDAuNzUzMzMzIDkuNDM3NCAwLjY5MjU4OCA5LjQ2MjYxQzAuNjMxODQzIDkuNDg3ODIgMC41NjY3MjIgOS41MDA3OSAwLjUwMDk1NSA5LjUwMDc5QzAuNDM1MTg3IDkuNTAwNzkgMC4zNzAwNjYgOS40ODc4MiAwLjMwOTMyMSA5LjQ2MjYxQzAuMjQ4NTc2IDkuNDM3NCAwLjE5MzQgOS40MDA0NiAwLjE0Njk1NSA5LjM1MzlaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K');
                            @apply inline-block bg-no-repeat bg-center w-3 h-3 -rotate-180 mt-0.5;
                        }
                        &.yotpo-disabled {
                            &:before {
                                content: '';
                                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNSIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDUgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNC44NTMxMSA5LjM1MzlDNC44OTk2NyA5LjMwNzQ1IDQuOTM2NjEgOS4yNTIyOCA0Ljk2MTgyIDkuMTkxNTNDNC45ODcwMyA5LjEzMDc5IDUgOS4wNjU2NyA1IDguOTk5OUM1IDguOTM0MTMgNC45ODcwMyA4Ljg2OTAxIDQuOTYxODIgOC44MDgyN0M0LjkzNjYxIDguNzQ3NTIgNC44OTk2NyA4LjY5MjM0IDQuODUzMTEgOC42NDU5TDEuMjA2MTEgNC45OTk5TDQuODUzMTEgMS4zNTM5QzQuOTQ2OTkgMS4yNjAwMSA0Ljk5OTc0IDEuMTMyNjcgNC45OTk3NCAwLjk5OTg5OUM0Ljk5OTc0IDAuODY3MTIzIDQuOTQ2OTkgMC43Mzk3ODYgNC44NTMxMSAwLjY0NTg5OUM0Ljc1OTIyIDAuNTUyMDEzIDQuNjMxODggMC40OTkyNjggNC40OTkxMSAwLjQ5OTI2OEM0LjM2NjMzIDAuNDk5MjY4IDQuMjM4OTkgMC41NTIwMTMgNC4xNDUxMSAwLjY0NTg5OUwwLjE0NTEwNiA0LjY0NTlDMC4wOTg1NDMyIDQuNjkyMzUgMC4wNjE2MDAzIDQuNzQ3NTIgMC4wMzYzOTM3IDQuODA4MjdDMC4wMTExODcxIDQuODY5MDEgLTAuMDAxNzg3MTMgNC45MzQxMyAtMC4wMDE3ODcxMyA0Ljk5OTlDLTAuMDAxNzg3MTMgNS4wNjU2NyAwLjAxMTE4NzEgNS4xMzA3OSAwLjAzNjM5MzcgNS4xOTE1M0MwLjA2MTYwMDMgNS4yNTIyOCAwLjA5ODU0MzIgNS4zMDc0NSAwLjE0NTEwNiA1LjM1MzlMNC4xNDUxMSA5LjM1MzlDNC4xOTE1NSA5LjQwMDQ2IDQuMjQ2NzMgOS40Mzc0IDQuMzA3NDcgOS40NjI2MUM0LjM2ODIyIDkuNDg3ODIgNC40MzMzNCA5LjUwMDc5IDQuNDk5MTEgOS41MDA3OUM0LjU2NDg3IDkuNTAwNzkgNC42Mjk5OSA5LjQ4NzgyIDQuNjkwNzQgOS40NjI2MUM0Ljc1MTQ5IDkuNDM3NCA0LjgwNjY2IDkuNDAwNDYgNC44NTMxMSA5LjM1MzlaIiBmaWxsPSIjQUJBOUE5Ii8+Cjwvc3ZnPgo=');
                                @apply inline-block bg-no-repeat bg-center w-3 h-3 rotate-0 mt-0.5;
                            }
                        }
                    }

                    &.yotpo-active {
                        @apply relative;
                        &:after {
                            content: '';
                            @apply h-px w-5 bg-black absolute left-1/3 bottom-6;
                        }
                    }
                }
            }
        }
        .write-question-review-buttons-container
            .write-question-review-button
            .write-question-review-button-text {
            @apply text-gold-1 text-xs leading-4 tracking-wider hover:font-bold #{!important};
        }
        .main-widget {
            .write-question-review-buttons-container {
                @apply w-full text-center #{!important};
                @apply float-none;
            }
        }
    }
}

.pdp-container {
    @apply md:flex;
}

.pdp-main-wrapper {
    @apply bg-grey-13;
    .container-classes {
        @apply flex flex-col px-4 2xl:max-w-[90rem] 2xl:pl-[8.375rem] 2xl:pr-[10.875rem];
    }
}

.pdp-info-wrap {
    @apply max-w-[90rem] mx-auto py-5;
    > div {
        display: inline; /* Apply inline property to the immediate child div */
    }
}

.pdp-short-description {
    > div {
        @apply w-full md:max-w-[30rem] mx-auto pt-6 pb-[0.8125rem] px-2.5 md:pb-9 md:px-0;
        > div {
            display: inline; /* Apply inline property to the immediate child div */
        }
        button {
            @apply font-bold underline hover:no-underline;
        }
    }
}

.pdp-hr {
    @apply w-full md:max-w-[30rem] h-[0.0625rem] mx-auto border-t border-solid border-grey-3 mb-[1.5rem] md:mb-[2.5rem];
}

.pdp-long-description-collapsible {
    @apply relative;
    > div {
        @apply px-[1.5rem] py-[0.75rem] md:py-[1.5rem] md:px-[1.313rem];
        > div:first-child {
            button {
                @apply w-full justify-between;
                h2 {
                    @apply font-extrabold leading-[1.063rem] tracking-normal text-blue-1 text-[0.875rem] md:text-[1rem] md:leading-[1.5rem] uppercase md:normal-case;
                }
            }
        }
    }
    :global {
        div.opened div.inner {
            @apply max-h-[1000vh];
        }
    }
}

.pdp-long-description {
    @apply border-t border-b border-grey-4 w-full max-w-[73.125rem] mx-auto mb-0;
    &-wrapper {
        @apply bg-white mt-8 md:mt-12 md:pt-10;
    }
}

.product-info-long-description {
    @apply flex flex-wrap flex-row md:gap-[7.313rem] pt-[2.188rem] pb-[1.75rem] md:pb-[1rem];
    > div {
        @apply max-w-[35.813rem] w-full p-0 m-0;
        :global {
            .block-content {
                @apply overflow-x-auto;
            }
            .block-footnote {
                @apply mb-2.5;
            }
        }
        p,
        ul,
        ol {
            @apply mb-2.5;
        }
        p:last-of-type {
            @apply mb-0;
        }
    }
    table {
        @apply h-fit;
        tr:last-child {
            @apply border-b border-grey-4;
        }
        th {
            @apply border-t border-l border-grey-4 bg-grey-5 px-[0.75rem] py-[0.625rem] text-blue-1 text-[0.75rem] leading-[0.875rem] tracking-[0.031rem] font-primary not-italic uppercase font-bold text-center;
        }
        td {
            @apply border-t border-l border-r border-grey-4 bg-white px-[0.75rem] py-[0.625rem] text-grey-1 text-[0.75rem] leading-[1.125rem] tracking-[-0.002rem] font-primary not-italic text-center w-[12.063rem];
        }
    }
    > table {
        @apply w-full mt-[1.5rem] md:w-[20.625rem] md:mt-0;
    }
    ul {
        @apply list-disc list-inside;
    }
    ol {
        @apply list-decimal list-inside;
    }
}

.pdp-breadcrumbs {
    @apply order-first py-8 md:py-11 md:order-none;
    > span {
        @apply text-blue-1 text-sm leading-6 flex flex-wrap items-center gap-x-2 capitalize;
        > a {
            @apply no-underline text-blue-1 hover:text-hover;
        }
        > span {
            @apply font-bold;
        }
    }
}

.pdp-price {
    @apply mb-5 md:mb-[2.375rem];
    :global {
        .product-price {
            @apply flex flex-wrap items-center gap-y-1.5;
        }
        strong {
            @apply text-xl leading-[1.625rem] font-primary font-extrabold uppercase tracking-normal md:text-2xl md:leading-[1.6875rem];
        }
        .product-price--value {
            @apply font-medium text-xs leading-[1.125rem] font-primary -tracking-[0.015625rem];
        }
    }
}

.pdp-price-table {
    :global {
        .qty-range {
            td {
                @apply font-bold;
            }
        }
        table tr {
            th {
                @apply 2xl:whitespace-nowrap;
            }
            th, td {
                &:last-child {
                    @apply pl-1.5 md:pl-2.5 lg:pl-5 xl:pl-8;
                }
            }
        }
        tbody {
            tr {
                &:not(:last-child) {
                    td {
                        @apply border-b border-solid border-grey-3;
                    }
                }
            }
        }
    }
}

.product-info {
    @apply px-5 pb-5 md:py-5;
    :global {
        .pdp-tabs {
            @apply bg-accents-1 mt-10 p-5 lg:px-10 xl:px-32 md:py-24 xl:mt-40;
            li {
                @apply w-24 md:w-40 xl:w-56 text-center border-2 border-accents-1 md:py-5 text-t-4 bg-accents-1;
                &[aria-selected='true'] {
                    @apply border-l-0 border-r-0 border-b-0 border-2 border-solid border-cms-1 bg-primary;
                }
            }
            .tabs {
                @apply max-w-screen-xl mx-auto w-full bg-primary text-primary;
            }
            .pagebuilder-column {
                @apply p-7 md:p-14;
            }
            .pb-text-root {
                @apply py-2;
            }
        }
        .herobanner {
            [class^='banner_root'] {
                @apply px-0 md:px-10;
            }
            .herobanner-content {
                @apply mx-5 md:mx-10 xl:mx-28;
                .content {
                    @apply my-3 max-w-lg;
                }
            }
            .heading {
                @apply font-bold text-3xl md:text-5xl mb-2 md:mb-4 max-w-md leading-none uppercase;
            }
        }
    }
}

.product-notify-signin-wrapper {
    @apply flex flex-row py-[0.625rem] px-[1.25rem] mb-[1.25rem] rounded-[0.313rem] bg-new-green-4 text-grey-1 font-primary text-[0.875rem] not-italic font-bold leading-[1.5rem] cursor-pointer underline hover:no-underline w-max md:w-full;
    svg {
        @apply mt-[0.25rem] mr-[0.5rem];
    }
}

.product-notify-guest-wrapper {
    @apply py-[1.875rem] px-[1.25rem] rounded-[0.313rem] bg-new-green-4 mb-[1.25rem] md:py-[0.625rem];
    > div {
        @apply flex text-grey-1 font-primary text-[0.875rem] not-italic font-bold underline w-[15.313rem] mb-[1.875rem] md:mb-[0.625rem] md:w-auto leading-4 md:leading-6;
        svg {
            @apply mt-0 mr-2 md:mt-1;
        }
    }
    form {
        @apply flex flex-col;
        > div {
            @apply flex flex-row flex-wrap mb-[0.625rem] md:justify-between md:flex-nowrap;
            > div {
                @apply w-full md:max-w-[13.438rem];
                label {
                    @apply text-grey-1 font-primary text-[0.75rem] not-italic font-bold uppercase leading-[0.875rem] tracking-[0.031rem] mb-[0.563rem];
                }
                input {
                    @apply p-[0.938rem] h-[3rem] mb-[0.75rem] md:mb-0 border rounded-none mt-0;
                    &::placeholder {
                        @apply text-grey-1 font-primary text-[0.75rem] not-italic font-medium uppercase leading-[1.125rem] tracking-[-0.016rem];
                    }
                }
            }
        }
        button {
            @apply bg-white border border-new-green-1 text-new-green-1 mb-0 mt-[0.813rem] md:mt-[0.625rem] md:mb-[1.25rem] hover:bg-new-green-1 hover:border-new-green-1 hover:border active:bg-new-green-1 active:border-new-green-1 active:border focus:bg-new-green-1 focus:border-new-green-1 focus:border;
        }
    }
}

.pdp-gallery-wrapper {
    @apply w-full mb-8 md:w-[52.64%] md:max-w-[37.1875rem] md:mb-0;
    :global {
        div[class*="ProductGallery_desktop-gallery"] {
            > div {
                @apply flex-grow;
            }
        }
        .pdp-gallery {
            @apply w-full md:sticky md:top-32;
        }
        [class*='FullscreenGallery_close'] {
            @apply bg-transparent;
        }
    }
}

.product-main {
    @apply w-full md:w-[47.46%] md:pl-5 lg:pl-10 xl:pl-[3.5625rem];

    .customize-bundle-title {
        @apply mb-2;
        .title {
            @apply mb-2.5 font-primary font-normal text-sm leading-5 text-black;
        }
    }

    .bundle-group {
        @apply pb-6 border-solid border-t pt-6;
        border-color: #edebeb;

        &:last-child {
            @apply border-b;
        }

        .group-title-container {
            @apply flex flex-row items-center mb-5;
        }

        .bundle-group-title {
            @apply font-bold text-black text-lg leading-6 uppercase;
        }
        .required-tag {
            @apply font-normal leading-5 font-primary ml-2.5;
        }

        .bundle-items-container {
            @apply flex flex-col;
        }

        .bundle-item-container {
            @apply border border-solid border-black py-3.5 pl-3 pr-5 flex items-center mb-2.5 cursor-pointer;
        }

        .radio-input {
            @apply accent-black w-[1.125rem] h-[1.125rem] cursor-pointer;
        }

        .item-title {
            @apply ml-2.5 font-primary font-normal text-sm leading-[1.3125rem];
        }

        .item-price {
            @apply ml-auto font-primary font-bold text-sm leading-[1.3125rem];
        }
    }
    .summary-container {
        @apply mt-6;
        .totals {
            @apply flex items-center mb-6;

            .total-value {
                @apply ml-auto font-secondary font-bold text-lg leading-6;
            }
        }

        .summary-title {
            @apply font-primary font-bold text-sm leading-[1.3125rem];
        }

        .summary-items-container {
            @apply flex justify-between;
        }

        .summary-item {
            @apply font-primary font-normal text-sm leading-[1.3125rem];
        }
    }

    :global {
        .short-description {
            @apply max-w-sm mb-5;
        }
        .pdp-more-description {
            @apply overflow-hidden mt-3;
        }

        .free-investor-kit,
        #_usgb-magento-fik-form {
            @apply hidden;
        }
    }
}
.product-details {
    @apply md:sticky top-24 z-10;
    :global {
        .cms-content {
            @apply flex;

            .row {
                > div {
                    @apply flex items-end;
                    > p {
                        @apply font-secondary text-2xl font-bold ml-2.5;
                    }
                }
            }

            .buy-back-wrapper {
                @apply bg-gold-3 py-2.5 pl-5 rounded-[0.3125rem] pr-8 md:pr-[4.125rem] max-w-[27.625rem] mt-5 md:mt-3.5 ml-1.5 md:ml-0;

                h4 {
                    @apply uppercase md:normal-case text-xs md:text-sm leading-[0.875rem] md:leading-6 text-grey-1 font-bold pb-3.5 md:pb-0 tracking-[0.03125rem] md:tracking-normal;
                }
                div {
                    @apply font-medium text-xs leading-[1.125rem] tracking-tight;

                    a {
                        @apply font-bold underline hover:no-underline;
                    }
                }
            }
        }
        #_usgb-magento-fik-form {
            @apply mt-5 mb-4;

            .hs-form-field {
                @apply mb-6 md:mb-5;
                label:not(.hs-form-radio-display):not(.hs-error-msg) {
                    @apply block uppercase text-xs leading-[0.875rem] tracking-[0.03125rem] font-bold text-grey-1 mb-2 relative;
                    .hs-form-required {
                        @apply text-red;
                    }
                }
                &.hs-usgb_pa_amount,
                &.hs_usgb_pa_comments {
                    > label:not(.hs-form-radio-display):not(.hs-error-msg) {
                        @apply mb-5 md:mt-5;
                    }
                }
            }

            input[type='text'],
            input[type='number'],
            input[type='tel'],
            input[type='email'],
            input[type='password'],
            select,
            .form-select,
            textarea {
                @apply appearance-none w-full border border-solid border-grey-1 bg-white text-black py-[0.6875rem] px-4 font-medium text-xs leading-[0.875rem] touch:text-base mb-0.5 rounded-none md:py-4 focus:shadow-none;

                &::placeholder {
                    @apply text-grey-1;
                }

                &:focus {
                    @apply border-gold-1 outline-2 outline outline-gold-1;
                    &::placeholder {
                        @apply text-grey-4;
                    }
                }

                &.error {
                    @apply border-red outline-none;
                    &::placeholder {
                        @apply text-grey-2;
                    }
                    &:focus {
                        @apply border-red border outline-none;
                        &::placeholder {
                            @apply text-grey-2;
                        }
                    }
                }

                &.disabled,
                &:disabled {
                    @apply outline-none border-grey-9;
                    &::placeholder {
                        @apply text-grey-9;
                    }
                    &:focus {
                        @apply border border-grey-9 outline-none;
                        &::placeholder {
                            @apply text-grey-9;
                        }
                    }
                }
            }
            .hs-error-msg {
                @apply text-grey-1 text-xs leading-[1.125rem] -tracking-[0.015625rem] inline-flex gap-2.5;
                &:before {
                    background-image: url('data:image/svg+xml,<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z" fill="%23E2204F" stroke="%23E2204F" stroke-width="0.7"/><path d="M6.31724 3.75816L6.42662 8.16962H7.57506L7.68444 3.75816H6.31724ZM7.00084 10.4483C7.41555 10.4483 7.74367 10.1338 7.74367 9.73277C7.74367 9.33173 7.41555 9.01727 7.00084 9.01727C6.58613 9.01727 6.258 9.33173 6.258 9.73277C6.258 10.1338 6.58613 10.4483 7.00084 10.4483Z" fill="white"/><path d="M6.3171 3.37637H5.92571L5.9354 3.76766L6.04478 8.17911L6.05401 8.55147H6.42648H7.57492H7.94739L7.95662 8.17911L8.06599 3.76766L8.07569 3.37637H7.6843H6.3171ZM7.0007 10.8301C7.6121 10.8301 8.12536 10.3586 8.12536 9.7328C8.12536 9.10697 7.6121 8.63549 7.0007 8.63549C6.3893 8.63549 5.87604 9.10697 5.87604 9.7328C5.87604 10.3586 6.3893 10.8301 7.0007 10.8301Z" fill="white" stroke="%23E2204F" stroke-width="0.7"/></svg>');
                    @apply content-[''] bg-no-repeat bg-contain w-3 h-3 mt-[0.1875rem] min-w-[0.75rem];
                }
            }

            .legal-consent-container {
                p {
                    @apply text-xs leading-[1.125rem] text-black;

                    a {
                        @apply underline hover:no-underline;
                    }
                }
            }

            .hs-submit {
                @apply mt-5;
                input[type='submit'] {
                    @apply cursor-pointer no-underline m-0 py-2.5 px-7 rounded-[3.125rem] text-sm leading-[0.9375rem] font-primary font-bold tracking-[0.03125rem] outline-none border-0 bg-blue-1 text-white focus:shadow-none transform transition-all duration-200 uppercase min-w-full md:min-w-0;
                    &.disabled,
                    &[disabled] {
                        @apply border-0 bg-grey-7 text-white opacity-100 pointer-events-none;
                        &:active,
                        &:focus {
                            @apply bg-grey-7 text-white;
                        }
                    }
                    &:active,
                    &:focus {
                        @apply border-0 bg-blue-1 text-white;
                    }
                    &:hover {
                        @apply border-0 bg-hover text-white transform transition-all duration-200;
                    }
                }
            }

            .hs_error_rollup .hs-main-font-element {
                @apply hidden;
            }
        }

        .value-proposition-container {
            @apply py-[0.1875rem] px-4 md:py-0 lg:px-0;

            [class*='columnGroup_root_'] > [class*='columnGroup_root_'] {
                @apply grid #{!important};
                @apply grid-cols-2 lg:grid-cols-[25%,20%,26%,22%] xl:grid-cols-[27%,21%,28%,18%] lg:gap-x-2.5 justify-between;
            }

            .pagebuilder-column {
                @apply mb-8 lg:mb-10;
                @apply w-auto flex-row lg:flex-col #{!important};

                &:nth-child(even) {
                    @apply pl-[1.125rem] lg:pl-0;
                    figure {
                        @apply mr-4 lg:mr-0;
                    }
                    .pb-text-root {
                        max-width: calc(100% - 2rem);
                        @apply lg:max-w-full;
                    }
                }
                figure {
                    @apply min-w-[1rem] max-w-[1rem] w-4 mr-[0.8125rem] mt-1.5 lg:mr-0 lg:mt-0 lg:mb-1.5;
                    img {
                        @apply h-auto max-h-4 w-auto;
                    }
                }
                .pb-text-root {
                    max-width: calc(100% - 1.875rem);
                    @apply flex-grow text-xs uppercase font-bold leading-[0.875rem] tracking-[0.03125rem] text-t-4 lg:max-w-full;
                }
            }
        }

        .order-over-50k {
            @apply flex items-center md:items-start pr-2.5 md:pr-0 mt-2.5 md:mt-[1.125rem] max-w-[26.5625rem];

            span {
                @apply font-primary text-sm md:text-lg font-extrabold leading-[1.125rem] md:leading-7;
                img {
                    @apply max-w-[2.5rem] mr-2.5;
                }
                span._green {
                    @apply text-new-green-1;
                }
            }
        }
    }
}

.product-name {
    @apply uppercase text-blue-1 text-2xl leading-tight font-secondary font-bold -tracking-[0.03125rem] md:text-[2rem] md:leading-normal;
}
.product-topinfo {
    @apply flex flex-col flex-wrap mb-3 mt-3.5 md:mt-4 md:mb-[1.4375rem];
    .sku,
    .rating {
        @apply inline-block font-bold;
    }
    .rating {
        @apply flex flex-wrap items-center justify-between gap-x-2.5 gap-y-2 mb-3 min-h-[1.25rem];
        :global {
            .yotpo.bottomLine {
                .yotpo-bottomline {
                    .yotpo-icon-star,
                    .yotpo-icon-half-star,
                    .yotpo-icon-empty-star {
                        @apply text-gold-1 text-sm pr-1 #{!important};
                    }
                }
            }
            .yotpo-stars {
                .yotpo-icon {
                    @apply text-s-2;
                }
                & + a {
                    @apply ml-[0.3125rem] uppercase;
                    @apply text-accents-7 font-bold #{!important};
                }
            }
            .yotpo .standalone-bottomline.star-clickable, 
            .yotpo .standalone-bottomline .star-clickable {
                @apply items-center;
            }
            div[class*="Forms_root"] {
                @apply leading-[0.875rem];
            }
        }
    }
    .sku {
        @apply hidden mb-4 md:mt-[0.4375rem] cursor-default;
    }
}
.qty-button-wrapper {
    @apply flex flex-wrap items-end gap-x-[1.3125rem] md:gap-x-3.5;
}
.qty-selector {
    @apply flex flex-col items-start uppercase font-medium max-w-[5.625rem];

    :global {
        .pdp-qty {
            @apply mb-0;
            input[type="number"] {
                @apply mx-0 h-full w-full text-grey-1 opacity-50 font-primary font-bold text-base leading-[2.8125rem] outline-none;
            }
        }
    }
}
.add-to-cart-wrapper {
    max-width: calc(100% - 6.9375rem);
    @apply flex-grow;
    @screen md {
        max-width: calc(100% - 6.5rem);
    }
    :global {
        html & button {
            @apply px-2;
        }
    }
}
.img {
    @apply w-full h-auto max-h-full object-cover;
}

.add-to-cart-button {
    @apply text-center w-full h-[3.0625rem];
}
.yotpo-review {
    :global {
        .yotpo-stars,
        .stars-wrapper,
        .yotpo-review-stars {
            .yotpo-icon {
                @apply text-s-2 text-2xl #{!important};
            }
        }
        .yotpo-default-button {
            @apply bg-s-1 #{!important};
        }
        .yotpo-review {
            @apply my-2.5 mx-0 border-s-1 #{!important};
        }
        .yotpo-small-box,
        .yotpo-thank-you {
            @apply border-s-1 #{!important};
        }
        .yotpo.yotpo-main-widget {
            .yotpo-thankyou-header {
                span {
                    @apply text-s-1 #{!important};
                }
            }
        }
        .yotpo-thankyou-content {
            span {
                @apply text-s-1 text-base;
            }
        }
        .yotpo-nav,
        .yotpo-bottomline-empty-state {
            @apply border-transparent #{!important};
        }
    }
}
.pdp-carousel-wrapper {
    .container-classes {
        @apply mb-0;
    }
}
